<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="notice">
		<div class="notice-data-add">
			<div class="list-search-row">
				<div class="list-search-col">
					<span class="list-search-label">日期筛选：</span>
					<el-date-picker class="list-search-date" v-model="date" value-format="yyyy-MM-dd" type="monthrange"
						range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleChange($event)">
					</el-date-picker>
				</div>
				<div class="list-search-col" >
					<span class="list-search-label">发布人：</span>
					<el-select class="list-search-select" v-model="searchForm.publisher" placeholder="请选择">
						<el-option v-for="(item, index) in accountList" :key="index" :label="item.account"
							:value="item.id">
						</el-option>
					</el-select>
				</div>
				<div class="list-search-col" style="width:200px">
					<span class="list-search-confirm" @click="jobHandle('query')">查询</span>
					<span class="list-search-cancel" @click="jobHandle('reset')">重置</span>
				</div>
			</div>
			<span @click="noticeHandle('add')" class="add-notice">+ 新增公告</span>
			
			
		</div>
		<div class="notice-data-list">
			<div class="data-list-content">
				<el-table :data="tableData">
					<el-table-column width="100px" type="index" label="序号">
					</el-table-column>
					<el-table-column prop="title" label="公告标题">
						<template slot-scope="scope">
							<el-popover placement="top" title="" content="" width="500" trigger="hover">
								<div class="action-popover-content" v-html="scope.row.title"></div>
								<div class="action-popover-btn" slot="reference" v-html="scope.row.title"></div>
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column prop="content" label="公告内容">
						<template slot-scope="scope">
							<el-popover placement="top" title="" content="" width="500" trigger="hover">
								<div class="action-popover-content" v-html="scope.row.content"></div>
								<div class="action-popover-btn" slot="reference" v-html="scope.row.content"></div>
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column prop="publisherAccount" label="发布人">
					</el-table-column>
					<el-table-column prop="createTime" label="创建时间">
					</el-table-column>
					<el-table-column prop="publish" label="操作">
						<template slot-scope="scope">
							<div class="list-content-action">
								<span @click="noticeHandle('edit', scope)">编辑</span>
								<el-divider direction="vertical"></el-divider>
								<span @click="noticeHandle('delete', scope)">删除</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="common-pageNation-pc">
				<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
					:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
				</el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		noticeListPc,
		noticeDeletePc
	} from "@/api/pc/notice";
		import {
		accountListPc,
	} from '@/api/pc/user'
	export default {
		data() {
			return {
				tableData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				date:undefined,
				searchForm:{},
				accountList:[]
			}
		},
		mounted() {
			this.getNoticeList();
			this.getAccountList()
		},
		methods: {
			//查询
			jobHandle(type){
				if(type=='query'){
					this.getNoticeList()
				}else{
					this.searchForm = {}
					this.date=""
					this.getNoticeList()
				}
			},
			// 获取账号列表
			getAccountList() {
				let params = {
					pageIndex: 1,
					pageSize: 9999
				}
				accountListPc(params).then((res) => {
					if (res.code == 0) {
						this.accountList = res.data
					}
				})
			},
			getNoticeList() {
				let params = {
					...this.searchForm,
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				}
				this.tableData = [];
				noticeListPc(params).then((res) => {
					if (res.code == 0) {
						this.tableData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
			currentChange(val) {
				this.pageIndex = val;
				this.getNoticeList();
			},
			handleChange(val){
				this.searchForm.startDay = val[0]
				this.searchForm.endDay = val[1]
			},
			noticeHandle(type,scope) {
				switch (type) {
					case "add":
						this.$router.push({
							path: "/pc/notice/noticeAdd?handleType=add"
						})
						break;
					case "edit":
						this.$router.push({
							path: `/pc/notice/noticeAdd?handleType=edit&&noticeId=${scope.row.id}`
						})
						break;
					case "delete":
						this.$confirm('确定删除当前公告吗？', '信息提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							noticeDeletePc(scope.row.id, {id: scope.row.id}).then((res) => {
								if (res.code == 0) {
									this.$message({
										type: 'success',
										message: '删除成功!'
									});
									this.pageIndex = 1;
									this.getNoticeList();
								}
							})
						}).catch(() => {});
						break;
				}
			}
		},
	}
</script>
<style lang="scss" scoped>
/deep/.list-search-col .el-input__inner {
		height: 32px;
		border-color: #DCDFE6;
		border-radius: 0px 2px 2px 0px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
	}
/deep/.list-search-col .el-input__icon.el-range__icon.el-icon-date {
		display: none;
	}

	/deep/.list-search-col .el-date-editor .el-range-input {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
	}

	/deep/.list-search-col .el-select .el-input .el-select__caret {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	/deep/.data-list-content .el-table th.el-table__cell>.cell {
		text-align: center;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #909399;
		line-height: 40px;
		background: #F7F8F9;
	}

	/deep/.data-list-content .el-table .el-table__cell {
		padding: 0 0;
	}

	/deep/.data-list-content .el-table .cell {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	/deep/.el-divider--vertical {
		background-color: #4392FA;
	}
	
	/deep/.action-popover-btn p {
		max-height: 60px;
		line-height: 20px;
		overflow: hidden;
		cursor: pointer;
		overflow-wrap: break-word;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		padding: 0 0;
		margin: 0 0;
	}
	
	/deep/.el-popover__reference {
		max-height: 60px;
		line-height: 20px;
		overflow: hidden;
		cursor: pointer;
		overflow-wrap: break-word;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.action-popover-btn {
		// max-height: 88px;
		// overflow: hidden;
		// cursor: pointer;
		// overflow-wrap: break-word;
		// display: -webkit-box;
		// -webkit-line-clamp: 2;
		// -webkit-box-orient: vertical;
	}

	.action-popover-content {
		max-height: 500px;
		overflow-y: auto;
	}
	.list-search-confirm {
		width: 68px;
		line-height: 32px;
		background: #4392FA;
		border-radius: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		margin-right: 8px;
		cursor: pointer;
	}

	.list-search-cancel {
		width: 68px;
		line-height: 32px;
		border: 1px solid #4392FA;
		border-radius: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #4392FA;
		cursor: pointer;
	}
	.notice {
		.list-search-row {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.list-search-col {
				width: 300px;
				display: flex;
				align-items: center;
				padding-right: 10px;
				box-sizing: border-box;

				.list-search-label {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 32px;
					color: #909399;
				}
				.list-search-ipt {
					height: 32px;
					line-height: 32px;
					flex: 1;
				}
				.list-search-date {
					flex: 1;
					height: 32px;
				}
				.list-search-select {
					width:150px;
					flex: 1;
					height: 32px;
				}
			}
		}
		.notice-data-add {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.add-notice {
				width: 102px;
				height: 32px;
				border: 1px solid #4392FA;
				border-radius: 2px;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #4392FA;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}
		}

		.notice-data-list {
			display: flex;
			flex-direction: column;

			.data-list-content {
				border: 1px solid #DCDFE6;
				border-radius: 2px;
				margin-top: 16px;

				.list-content-action {
					display: flex;
					align-items: center;
					justify-content: center;

					span {
						font-size: 12px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 48px;
						color: #4392FA;
						cursor: pointer;
					}
				}
			}
		}
	}
</style>
